import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"


export default function Logo () {
  return (
  <StaticQuery 
    query = {graphql`
    query {
      file(sourceInstanceName: { eq: "images" }, name: { eq: "CTP_Logo2020" }) {
        childImageSharp {
          fluid(quality: 95, maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `}
    render = { data => (
    <div>
      <Img fluid={data.file.childImageSharp.fluid} />
    </div>
    )}
  />    
  )
};

